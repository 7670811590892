<template>
  <div class="Part">
    <div class="input">
      <!-- 功能按钮 -->
      <el-row>
        <el-button type="primary" size="mini" @click="addTask" v-if="authe.Hotel_Administration_RepairClean_AddMaintenance">添加维保</el-button>
        <el-button type="primary" size="mini" @click="addEvolve" v-if="authe.Hotel_Administration_RepairClean_AddProgress">添加进展</el-button>
        <el-button type="primary" size="mini" @click="advancedFilter">高级筛选</el-button>
        <!-- <el-button type="primary" size="mini">高级筛选</el-button> -->

        <div class="addTask">
          <!-- 添加维保对话框开始 -->
          <el-dialog
            title="添加维保"
            :visible.sync="addTaskDialog"
            :close-on-click-modal="false"
            width="800px"
            @close="closeTaskDialog"
          >
            <el-form :model="addTaskFrom" ref="addTaskFrom" label-width="75px">
              <!-- 第一行 -->
              <el-row>
                <el-col :span="8">
                  <el-form-item
                    label="维保归属"
                    prop="affiliation"
                    :rules="[{ required: true, message: '请选择维保归属',trigger: ['blur','change']}]"
                  >
                    <el-input
                      autocomplete="off"
                      size="small"
                      v-model="addTaskFrom.affiliation"
                      @click.native="selectAddressBelong('选择地址')"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item>
                    <el-input v-model="addTaskFrom.affiliationType" size="small" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item
                    label="负责人"
                    prop="principal"
                    :rules="[{ required: true, message: '请选择审批人',trigger: ['blur','change']}]"
                  >
                    <el-cascader
                      v-model="addTaskFrom.principal"
                      :options="userNameList"
                      :props="{ expandTrigger: 'hover'}"
                      size="small"
                      clearable
                    ></el-cascader>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 第二行 -->
              <el-row>
                <el-col :span="8">
                  <el-form-item
                    label="维保类型"
                    label-width="75px"
                    prop="type"
                    :rules="[{ required: true, message: '请选择维保类型',trigger: ['blur','change']}]"
                  >
                    <el-select v-model="addTaskFrom.type" size="small">
                      <el-option label value></el-option>
                      <el-option label="保洁服务" value="保洁服务"></el-option>
                      <el-option label="退房维修" value="退房维修"></el-option>
                      <el-option label="装修维保" value="装修维保"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item
                    label="责任归属"
                    label-width="75px"
                    prop="dutyAffiliation"
                    :rules="[{ required: true, message: '请选择责任归属',trigger: ['blur','change']}]"
                  >
                    <el-select v-model="addTaskFrom.dutyAffiliation" size="small">
                      <el-option label value></el-option>
                      <el-option label="负责人" value="负责人"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="维保等级">
                    <el-select v-model="addTaskFrom.taskGrade" size="small">
                      <el-option label="1" value="1"></el-option>
                      <el-option label="2" value="2"></el-option>
                      <el-option label="3" value="3"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 第三行 -->
              <el-row>
                <el-col :span="8">
                  <el-form-item label="客户姓名">
                    <el-input v-model="addTaskFrom.customerName" size="small"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="客户电话">
                    <el-input v-model="addTaskFrom.customerPhone" size="small"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="期望时间">
                    <el-select v-model="addTaskFrom.expectedTime" size="small">
                      <el-option label value></el-option>
                      <el-option label="尽快" value="尽快"></el-option>
                      <el-option label="今天" value="今天"></el-option>
                      <el-option label="明天" value="明天"></el-option>
                      <el-option label="后天" value="后天"></el-option>
                      <el-option label="本周末" value="本周末"></el-option>
                      <el-option label="电话联系" value="电话联系"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 第四行 -->
              <el-row>
                <el-col :span="24">
                  <el-form-item
                    label="维保描述"
                    prop="taskDescribe"
                    :rules="[{ required: true, message: '请输入维保描述',trigger: ['blur','change']}]"
                  >
                    <el-input
                      type="textarea"
                      v-model="addTaskFrom.taskDescribe"
                      :autosize="{minRows:2,maxRows:6}"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 第五行 -->
              <el-row>
                <el-col :span="24">
                  <el-form-item label-width="0px">
                    <el-upload
                      class="upload-demo"
                      action="https://upload-z2.qiniup.com"
                      :data="picData"
                      :file-list="addTaskFrom.imgUploadList"
                      list-type="picture"
                      :before-upload="beforeUpload"
                      :on-success="handleAvatarSuccess"
                      :before-remove="beforeRemove"
                      :on-remove="handleRemove"
                      :on-preview="handlePictureCardPreview"
                    >
                      <el-button size="small" type="primary">上传附件</el-button>
                      <el-button size="small" type="primary" @click.stop="phoneUpload">手机上传</el-button>
                      <el-button
                        size="small"
                        type="primary"
                        @click.stop="getTempImg(addTaskFrom.random)"
                      >
                        <i class="el-icon-refresh"></i>
                      </el-button>
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 第六行 -->
              <el-row>
                <el-form-item label-width="0px">
                  <el-checkbox v-model="addTaskFrom.deal1" label="短信提醒" name="deal1"></el-checkbox>
                  <el-checkbox v-model="addTaskFrom.deal2" label="公众号提醒" name="deal2"></el-checkbox>
                </el-form-item>
              </el-row>
            </el-form>

            <div slot="footer">
              <el-button @click="addTaskDialog = false">取 消</el-button>
              <el-button type="primary" @click="saveAddTask">保 存</el-button>
            </div>
          </el-dialog>
          <!-- 添加维保对话框结束 -->

          <!-- 维保归属对话框开始 -->
          <select-address
            v-if="taskAffiliationDialog"
            :isDialogShow="taskAffiliationDialog"
            :isTitle="taskAffiliationDialogTitle"
            @close="selectRoomDblclick"
          >
          </select-address>
          <!-- 维保归属对话框结束 -->
        </div>
        <div class="evolve">
          <el-dialog
            title="添加进展"
            :visible.sync="addEvolveDialog"
            :close-on-click-modal="false"
            width="630px"
            @close="closeAddEvolve"
          >
            <el-form ref="evolveForm" :model="evolveForm" label-width="80px">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="进展时间">
                    <el-date-picker
                      v-model="evolveForm.time"
                      type="datetime"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="进展状态">
                    <el-select v-model="evolveForm.state" placeholder="请选择维保状态">
                      <el-option label value></el-option>
                      <el-option label="未完成" value="未完成"></el-option>
                      <el-option label="已完成" value="已完成"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="进展详细">
                <el-input type="textarea" v-model="evolveForm.describe"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="addEvolveDialog = false">取 消</el-button>
              <el-button type="primary" @click="saveEvolve">确 定</el-button>
            </span>
          </el-dialog>
        </div>
      </el-row>

      <!-- 表格筛选 -->
      <el-row style="padding: 5px 5px 5px 0;">
        <!-- 日常分类：
                <el-select v-model="scClassify" size="mini" style="width:100px">
                  <el-option label="全部维保" value="全部维保"></el-option>
                  <el-option label="项目维保" value="项目维保"></el-option>
                  <el-option label="供应商维保" value="供应商维保"></el-option>
                  <el-option label="库房" value="库房"></el-option>
                  <el-option label="公区维保" value="公区维保"></el-option>
        </el-select>&nbsp;&nbsp;-->
        维保描述：
        <el-input v-model="scTaskDescribe" size="mini" @change="scChange" style="width:125px"></el-input>&nbsp;&nbsp;
        登记时间：
        <el-date-picker
          v-model="scRegisterTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
          style="width:205px"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          @input="scChange"
        ></el-date-picker>
      </el-row>
      <el-row v-show="filter" style="padding: 5px 5px 5px 0;">
        维保类型：
        <el-cascader
          style="width:125px"
          clearable
          size="mini"
          v-model="repTypeRp"
          :options="repTypeRpData"
          @change="scChange"
        ></el-cascader>&nbsp;&nbsp;
        维保状态：
        <el-select style="width:125px" clearable size="mini" v-model="repState" @change="scChange">
          <el-option label value></el-option>
          <el-option label="未完成" value="未完成"></el-option>
          <el-option label="未领取" value="未领取"></el-option>
          <el-option label="跟进中" value="跟进中"></el-option>
          <el-option label="事件完成" value="事件完成"></el-option>
          <el-option label="已复核" value="已复核"></el-option>
        </el-select>&nbsp;&nbsp;
        维保等级：
        <el-select
          @change="scChange"
          size="mini"
          style="width:125px"
          v-model="repGrade"
          placeholder="请选择"
          clearable
        >
          <el-option
            v-for="item in gradeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>&nbsp;&nbsp;
        负责人：
        <v-cascader
          :options="userNameList"
          v-model.lazy="repCharge"
          @change="scChange"
          :strictly="true"
          filterable
          clearable
          :props="{ expandTrigger: 'hover' }"
          size="mini"
          style="width:125px"
        ></v-cascader>&nbsp;&nbsp;
        登记人：
        <v-cascader
          :options="userNameList"
          v-model.lazy="regRegistrant"
          @change="scChange"
          :strictly="true"
          filterable
          clearable
          :props="{ expandTrigger: 'hover' }"
          size="mini"
          style="width:125px"
        ></v-cascader>
      </el-row>
    </div>
    <!-- 维保数据表 -->
    <div style="text-align:center;">
      <!-- {{taskData}} -->
      <el-table
        :data="taskData"
        ref="taskData"
        style="width: 100%"
        highlight-current-row
        :height="tableHeight"
        @row-click="selectTaskRow"
        @row-dblclick="dbRow"
        :row-class-name="tableRowClassName"
      >
        <el-table-column prop="repState" label="维保状态"></el-table-column>
        <el-table-column prop="repTypeRp" label="维保类型"></el-table-column>
        <el-table-column prop="keyAdministrator" label="类型/地址" show-overflow-tooltip></el-table-column>
        <el-table-column prop="repReportingTime" label="登记时间" show-overflow-tooltip></el-table-column>
        <el-table-column prop="repFinishTime" label="完成时间" show-overflow-tooltip></el-table-column>
        <el-table-column prop="repGrade" label="维保等级"></el-table-column>
        <el-table-column prop="repEventRp" label="维保描述" show-overflow-tooltip></el-table-column>
        <el-table-column prop="repContactsPhone" label="客户电话" show-overflow-tooltip></el-table-column>
        <el-table-column prop="repairman" label="负责人"></el-table-column>
        <el-table-column prop="repUseTime" label="维保耗时" show-overflow-tooltip></el-table-column>
        <el-table-column prop="repToReceive" label="领取" show-overflow-tooltip>
          <template slot-scope="scope">
            <div
              @click="clickToReceive(scope.$index, scope.row)"
              :class="{red:scope.row.repState=='未领取'}"
            >{{scope.row.repToReceive}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="repProgressRp" label="进展" show-overflow-tooltip></el-table-column>
        <el-table-column prop="repImgNum" label="图片数量"></el-table-column>
        <el-table-column prop="repReturningRp" label="复核结果">
          <template slot-scope="scope">
            <div
              @click="clickReturning(scope.$index, scope.row)"
              :class="{red:scope.row.repReturningRp=='未复核',blue:scope.row.repReturningRp=='已复核'}"
            >{{scope.row.repReturningRp}}</div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        :total="total"
        :page-size="pageSize"
        :page-sizes="[5,10,20,40]"
        :current-page="currentPage"
        layout="total, sizes, prev, pager, next, jumper"
        background
        @size-change="sizeChange"
        @current-change="currentChange"
      ></el-pagination>
    </div>
    <!-- 详细 -->
    <el-dialog
      title="维保详细"
      :visible.sync="taskDetailDialog"
      :close-on-click-modal="false"
      width="800px"
      style="height:800px"
    >
      <div class="project-detail">
        <div class="describe">
          <el-row>
            <el-col :span="12">
              <span>地址/项目名：</span>
              {{taskRow.hsAddCommunity}}-{{taskRow.hsRoomType}}-{{taskRow.hsAddDoorplateno}}
            </el-col>
            <el-col :span="12">
              <span>维保类型：</span>
              {{taskRow.repTypeRp}}
            </el-col>
          </el-row>
        </div>
        <div class="describe">
          <el-row>
            <el-col :span="12">
              <span>登记人：</span>
              {{taskRow.username}}
            </el-col>
            <el-col :span="12">
              <span>负责人：</span>
              {{taskRow.repairman}}
            </el-col>
          </el-row>
        </div>
        <div class="describe">
          <el-row>
            <el-col :span="12">
              <span>客户姓名：</span>
              {{taskRow.repContacts}}
            </el-col>
            <el-col :span="12">
              <span>客户电话：</span>
              {{taskRow.repContactsPhone}}
            </el-col>
          </el-row>
        </div>
        <div class="describe">
          <el-row>
            <el-col :span="12">
              <span>维保状态：</span>
              {{taskRow.repState}}
            </el-col>

            <el-col :span="12">
              <span>维保等级：</span>
              {{taskRow.repGrade}}
            </el-col>
          </el-row>
        </div>
        <div class="describe">
          <el-row>
            <el-col :span="12">
              <span>登记时间：</span>
              {{taskRow.repReportingTime}}
            </el-col>
            <el-col :span="12">
              <span>领取时间：</span>
              {{taskRow.repToReceive}}
            </el-col>
          </el-row>
        </div>
        <div class="describe">
          <el-row>
            <el-col :span="12">
              <span>期望时间：</span>
              {{taskRow.repHopeTime}}
            </el-col>
            <!-- <el-col :span="12"> -->
            <el-button plai size="mini" @click="accessory" v-if="authe.Hotel_Administration_RepairClean_Annex">
              <i class="el-icon-picture-outline-round"></i>附件
            </el-button>
            <!-- </el-col> -->
          </el-row>
        </div>
        <div class="describe">
          <el-row>
            <el-col :span="24">
              <span>维保描述：</span>
              {{taskRow.repEventRp}}
            </el-col>
          </el-row>
        </div>
      </div>
      <el-tabs v-model="detailTab">
        <el-tab-pane label="进展" name="evolve">
          <el-table :data="evolveBill" height="150" highlight-current-row>
            <el-table-column prop="proTime" label="进展时间"></el-table-column>
            <el-table-column prop="suStaffName" label="负责人"></el-table-column>
            <el-table-column prop="proState" label="进展状态"></el-table-column>
            <el-table-column prop="proRemark" label="备注" show-overflow-tooltip></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="复核" name="review">
          <el-table :data="reviewBill" height="150" highlight-current-row>
            <el-table-column prop="retTime" label="复核时间"></el-table-column>
            <el-table-column prop="suStaffName" label="负责人"></el-table-column>
            <el-table-column prop="retResult" label="复核结果"></el-table-column>
            <el-table-column prop="rteRemark" label="备注"></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <el-divider></el-divider>
      <div style="text-align: center;">
        <el-button type="primary" size="mini" @click="next(false)">上一条</el-button>
        <el-button type="primary" size="mini" @click="modiPrincipal" v-if="authe.Hotel_Administration_RepairClean_ResponsiblePerson">修改负责人</el-button>
        <el-button type="primary" size="mini" @click="next(true)">下一条</el-button>
      </div>
    </el-dialog>
    <!-- 修改负责人 -->
    <el-dialog
      title="修改负责人"
      :visible.sync="modiPrincipalDialog"
      width="600px"
      :close-on-click-modal="false"
      @close="closePrincipal"
    >
      <el-form :model="principalForm" ref="principalForm" label-width="80px">
        <el-form-item label="原负责人">
          <el-cascader
            v-model="principalForm.oldPrincipal"
            :options="userNameList"
            :props="{ expandTrigger: 'hover' }"
            size="small"
            disabled
          ></el-cascader>
        </el-form-item>
        <el-form-item label="新负责人">
          <el-cascader
            v-model="principalForm.newPrincipal"
            :options="userNameList"
            :props="{ expandTrigger: 'hover' }"
            size="small"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="最新进展">
          <el-input type="textarea" :rows="2" v-model="principalForm.newEvo"></el-input>
        </el-form-item>
        <el-form-item>
          <el-checkbox-group v-model="principalForm.topic">
            <el-checkbox label="是否短信提醒" name="topic"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="modiPrincipalDialog = false">取 消</el-button>
        <el-button type="primary" @click="saveModiPrincipal">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 复核 -->
    <el-dialog
      title="添加复核"
      :close-on-click-modal="false"
      :visible.sync="returnDialog"
      @close="closeReturnDialog"
    >
      <el-form :model="returnFrom" ref="returnFrom" label-width="80px">
        <el-form-item label="复核人">
          <el-input v-model="returnFrom.name" disabled size="small" style="width:30%"></el-input>
        </el-form-item>
        <el-form-item label="复核结果" size="small" style="width:40%">
          <el-select v-model="returnFrom.result">
            <el-option label="合格" value="合格"></el-option>
            <el-option label="不合格" value="不合格"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="备注/原因">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            v-model="returnFrom.cause"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="returnDialog = false">取 消</el-button>
        <el-button type="primary" @click="saveReturn">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看原图 -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" />
    </el-dialog>
    <!-- 附件 -->
    <el-dialog
      :visible.sync="accessoryDia"
      width="800px"
      :close-on-click-modal="false"
      title="查看附件/上传附件"
      @close="closeAccessory"
    >
      <div style="min-height:500px">
        <el-upload
          class="upload-demo"
          action="https://upload-z2.qiniup.com"
          :data="picData"
          :file-list="evolveForm.imgUploadList"
          list-type="picture"
          :before-upload="beforeUpload"
          :on-success="handleAvatarSuccess"
          :before-remove="beforeRemove"
          :on-remove="handleRemove"
          :on-preview="handlePictureCardPreview"
        >
          <el-button size="small" type="primary">上传附件</el-button>
          <el-button size="small" type="primary" @click.stop="phoneUpload">手机上传</el-button>
          <el-button size="small" type="primary" @click.stop="getTempImg(evolveForm.random)">
            <i class="el-icon-refresh"></i>
          </el-button>
        </el-upload>
      </div>
      <span slot="footer">
        <el-button @click="accessoryDia = false">取 消</el-button>
        <el-button type="primary" @click="saveAccessory">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 微信扫一扫上传 -->
    <el-dialog
      :visible.sync="phoneUploadDia"
      title="微信扫一扫上传"
      width="280px"
      :close-on-click-modal="false"
      @close="closePhoneUploadDia"
    >
      <el-row type="flex" class="row-bg" justify="space-around">
        <el-col :span="24">
          <canvas id="QRCode"></canvas>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import {
  taskCostTime,
  getTaskTime,
  dateFtt,
  gerUserName
} from "../../assets/utils";
import { mapState } from "vuex";
import QRCode from "qrcode"; //引入生成二维码插件
import selectAddress from '../../components/selectAddress/selectAddress'
export default {
  components: {
    'select-address':selectAddress
  },
  data() {
    return {
      repGrade: "",
      repState: "未完成",
      repTypeRp: "",
      repStateData: [],
      repTypeRpData: [],
      gradeList: [
        { value: 1, label: 1 },
        { value: 2, label: 2 },
        { value: 3, label: 3 }
      ],

      addTaskDialog: false,
      taskAffiliationDialogTitle: false,
      taskAffiliationDialog: false,
      addEvolveDialog: false,
      taskDetailDialog: false,
      modiPrincipalDialog: false,
      returnDialog: false,

      taskFromRef: ["addTaskFrom"],
      addTaskFrom: {
        affiliation: "",
        affiliationType: "",
        principal: "",
        type: "",
        dutyAffiliation: "负责人",
        taskGrade: "3",
        customerName: "",
        customerPhone: "",
        expectedTime: "尽快",
        taskDescribe: "",
        imgUploadList: [],
        deal1: "",
        deal2: "",
      },

      evolveForm: {
        time: dateFtt("yyyy-MM-dd hh:mm:ss", new Date()),
        state: "",
        describe: "",
        imgUploadList: [],
        imgDelId: []
      },

      // scClassify: "全部维保",
      scTaskDescribe: "",
      scRegisterTime: null,

      taskRow: "",
      dataRow: "",
      taskData: [],

      total: 0,
      pageSize: 10,
      currentPage: 1,

      detailTab: "evolve",
      principalForm: {
        oldPrincipal: "",
        newPrincipal: "",
        newEvo: "",
        topic: ""
      },
      evolveBill: [],
      reviewBill: [],
      returnFrom: {
        name: "",
        result: "合格",
        cause: ""
      },
      tableHeight: window.innerHeight - 225,
      picData: {
        token: "",
        "x:co": "",
        "x:filename": "",
        "x:photoRandom": "", //十二位随机数
        "x:photoTableType": "", //审批or维保
        "x:photoMimeType": "", //文件or图片
        "x:photoBucket": "" //公用or私有
      },
      dialogVisible: false,
      dialogImageUrl: "",
      accessoryDia: false,
      QRCodeMsg: "",
      phoneUploadDia: false,
      regRegistrant: [],
      filter: false,
      Counter:0
    };
  },
  computed: {
    ...mapState(["userNameList", "userName", "currentUserInfo",'authe'])
  },
  watch: {
    // 通过监听获取数据
    QRCodeMsg(val) {
      // 获取页面的canvas
      var msg = document.getElementById("QRCode");
      // 将获取到的数据（val）画到msg（canvas）上
      QRCode.toCanvas(msg, val, function(error) {
        console.log(error);
      });
    }
  },
  created() {
    let data = this.currentUserInfo;
    this.repCharge = [
      data.storefrontId.toString(),
      data.departmentId.toString(),
      data.userCoding.toString()
    ];
    this.getTaskData();
    this.getRepStateData();
    this.getRepTypeRpData();
  },
  methods: {
    //获取维保类型列表repTypeRp
    getRepTypeRpData() {
      this.$axios({
        url: `/common1/oddjobs`,
        methods: "get",
        params: {
          oddjobsType: 1
        }
      }).then(res => {
        for (let i = 0; i < res.data.result.records.length; i++) {
          this.repTypeRpData[i] = {};
          this.repTypeRpData[i].value = res.data.result.records[i].repTypeRp;
          this.repTypeRpData[i].label = res.data.result.records[i].repTypeRp;
        }
        //一级去重
        let brandsListArr = [];
        let brandsListObj = {};
        for (let i = 0; i < this.repTypeRpData.length; i++) {
          if (!brandsListObj[this.repTypeRpData[i].label]) {
            brandsListArr.push(this.repTypeRpData[i]);
            brandsListObj[this.repTypeRpData[i].label] = true;
          }
        }
        this.repTypeRpData = brandsListArr;
      }).catch(err => {
        this.isError(err,this)
      });
    },
    //获取维保状态列表
    getRepStateData() {
      this.$axios({
        url: `/common1/oddjobs`,
        methods: "get",
        params: {
          oddjobsType: 1
        }
      }).then(res => {
        for (let i = 0; i < res.data.result.records.length; i++) {
          this.repStateData[i] = {};
          this.repStateData[i].value = res.data.result.records[i].repState;
          this.repStateData[i].label = res.data.result.records[i].repState;
        }
        //一级去重
        let brandsListArr = [];
        let brandsListObj = {};
        for (let i = 0; i < this.repStateData.length; i++) {
          if (!brandsListObj[this.repStateData[i].label]) {
            brandsListArr.push(this.repStateData[i]);
            brandsListObj[this.repStateData[i].label] = true;
          }
        }
        this.repStateData = brandsListArr;
      }).catch(err => {
        this.isError(err,this)
      });
    },
    //获取维保数据
    getTaskData() {
      let storefrontId = ''
      let departmentId = ''
      let repRepairPeopleId = ''
      if (this.repCharge.length == 1) {
        storefrontId = this.repCharge[0]
      } else if (this.repCharge.length == 2) {
        departmentId = this.repCharge[1]
      } else if (this.repCharge.length == 3) {
        repRepairPeopleId = this.repCharge[2]
      }
      if(this.Counter==0){
        repRepairPeopleId=window.sessionStorage.getItem('userCoding')   
        this.Counter=1
      }
      let repStorefront = ''
      let repDepartment = ''
      let repUserId = ''
      if (this.regRegistrant.length == 1) {
        repStorefront = this.regRegistrant[0]
      } else if (this.regRegistrant.length == 2) {
        repDepartment = this.regRegistrant[1]
      } else if (this.regRegistrant.length == 3) {
        repUserId = this.regRegistrant[2]
      }
      this.$axios({
        url: `/common1/oddjobs`,
        methods: "get",
        params: {
          oddjobsType: 1,
          cursor: this.currentPage,
          limit: this.pageSize,
          repEventRp: this.scTaskDescribe,
          startTime:
            this.scRegisterTime == null ? null : this.scRegisterTime[0],
          endTime: this.scRegisterTime == null ? null : this.scRegisterTime[1],
          repState: this.repState,
          repTypeRp: this.repTypeRp[0],
          repGrade: this.repGrade,

          storefrontId: storefrontId,//负责人门店id
          departmentId: departmentId,//负责人部门id
          repRepairPeopleId: repRepairPeopleId,//负责人id

          repStorefront: repStorefront,//登记人门店id
          repDepartment: repDepartment,//登记人部门id
          repUserId: repUserId,//登记人id
        }
      }).then(res => {
        console.log(res);
        this.total = res.data.result.total;
        this.taskData = res.data.result.records;
        let data = this.taskData;

        console.log(data);

        for (let i of data) {
          let a =
            i.addCity == null || i.addCity == undefined
              ? ""
              : `${i.addCity}\u3000`;
          let b =
            i.addCommunity == null || i.addCommunity == undefined
              ? ""
              : `${i.addCommunity}\u3000`;
          let c =
            i.addRoomType == null || i.addRoomType == undefined
              ? ""
              : `${i.addRoomType}\u3000`;
          let d =
            i.addBuilding == null || i.addBuilding == undefined
              ? ""
              : `${i.addBuilding}\u3000`;
          let e =
            i.addDoorplateno == null || i.addDoorplateno == undefined
              ? ""
              : `${i.addDoorplateno}`;

          let f =
            i.repHouseId == null || i.repHouseId == undefined
              ? ""
              : `${i.repHouseId}\u3000`;
          let g =
            i.repHouse4rentId == null || i.repHouse4rentId == undefined
              ? ""
              : `${i.repHouse4rentId}\u3000`;
          let h =
            i.repHouse4storeId == null || i.repHouse4storeId == undefined
              ? ""
              : `${i.repHouse4storeId}\u3000`;
          let k =
            i.repCocId == null || i.repCocId == undefined
              ? ""
              : `${i.repCocId}`;
          if (a == "项目" || a == "库房" || a == "供应商" || a == "公区") {
            b = a;
          }
          if (a != "项目" && a != "库房" && a != "供应商" && a != "公区") {
            i.keyAdministrator = b + c + d + e;
          }
          if (
            (i.hsId == null || i.hsId == "") &&
            (i.houseCoding == null || i.houseCoding == "")
          ) {
            if (
              i.repCocId !== null &&
              i.repCocId !== "" &&
              i.repCocId != undefined
            ) {
              i.keyAdministrator = i.repContacts;
            } else {
              i.keyAdministrator = "无归属维保";
            }
          }
          if (i.repReturningRp == "未回访") {
            i.repReturningRp = "未复核";
          }
          if (i.repUseTime == "") {
            if (i.repToReceive == "") {
              i.repUseTime = "维保未领取";
            } else {
              let end = new Date();
              let statr = i.repToReceive;
              i.repUseTime = taskCostTime(statr, end);
            }
          } else {
            let end = i.repUseTime;
            let statr = i.repToReceive;
            i.repUseTime = taskCostTime(statr, end);
          }
        }
      }).catch(err => {
        this.isError(err,this)
      })
    },
    //获取进展列表
    getEvolveData(row) {
      this.$axios({
        url: `/common1/oddjobsFollowup/${row.repId}`,
        method: "get"
      }).then(res => {
        console.log(res);
        this.evolveBill = res.data.result.records;
      }).catch(err => {
        this.isError(err,this)
      })
    },
    //获取复核列表
    getReturnData(row) {
      this.$axios({
        url: `/common1/oddjobsInterview/${row.repId}`,
        method: "get"
      }).then(res => {
        console.log(res);
        this.reviewBill = res.data.result.records;
      }).catch(err => {
        this.isError(err,this)
      })
    },
    scChange() {
      this.getTaskData();
    },
    // 点击添加维保按钮
    addTask() {
      this.addTaskDialog = true;
      this.addTaskFrom.random = parseInt(
        (Math.random() * 9 + 1) * 100000000000
      );
    },
    //关闭添加维保对话框
    closeTaskDialog() {
      this.addTaskFrom = {
        affiliation: "",
        affiliationType: "",
        principal: "",
        type: "",
        dutyAffiliation: "负责人",
        taskGrade: "3",
        customerName: "",
        customerPhone: "",
        expectedTime: "尽量",
        taskDescribe: "",
        imgUploadList: [],
        deal1: "",
        deal2: "",
      };
      this.resetForm(this.taskFromRef, this);
    },
    //保存添加维保
    saveAddTask() {
      let time = getTaskTime(this.addTaskFrom.expectedTime);

      if (this.submitForm(this.taskFromRef, this)) {
        this.$axios({
          url: `/common1/oddjobs`,
          method: "post",
          data: {
            oddjobsType: 1, //1为维保，2为任务

            hsId: this.addTaskFrom.hsId,
            keyAdministrator: this.addTaskFrom.affiliationType, //维保归属
            address: this.addTaskFrom.affiliation,

            repStorefront: "", //公司id
            repDepartment: "", //部门id
            repUserId: "", //用户id

            repRepairPeopleId: this.addTaskFrom.principal[2], //负责人id

            repTypeRp: this.addTaskFrom.type, //维保类型
            repResponsibility: this.addTaskFrom.dutyAffiliation, //责任归属
            repGrade: this.addTaskFrom.taskGrade, //维保等级

            repContacts: this.addTaskFrom.customerName, //客户姓名
            repContactsPhone: this.addTaskFrom.customerPhone, //客户电话
            repHopeTime: this.addTaskFrom.expectedTime, //期望时间
            repEventRp: this.addTaskFrom.taskDescribe, //维保描述

            sendMessage: this.addTaskFrom.deal1 == true ? 1 : 0,
            sendWxMessage: this.addTaskFrom.deal2 == true ? 1 : 0,

            repTaskTime: `${time} 00:00:00`,

            randomNum: this.addTaskFrom.random,
            tableType: "维保"
          }
        }).then(res => {
          this.$message.success("添加成功");
          this.getTaskData();
          this.closeTaskDialog();
          this.addTaskDialog = false;
        }).catch(res => {
          this.isError(err,this)
        });
      }
    },
    // 点击添加进展按钮
    addEvolve() {
      if (!this.dataRow) {
        this.$message.warning("请先选中一条记录");
      } else if (this.dataRow.repState == "未领取") {
        this.$message.warning("维保未领取，无法添加进展！");
      } else if (this.dataRow.repState == "事件完成") {
        this.$message.warning("维保已完成，无法添加进展！");
      } else {
        this.addEvolveDialog = true;
      }
    },
    //高级筛选
    advancedFilter() {
      this.filter = this.filter ? false : true;
    },
    //保存进展
    saveEvolve() {
      console.log("保存");
      this.$axios({
        url: `/common1/oddjobsFollowup/taskFollow`,
        method: "post",
        data: {
          proRepairId: this.dataRow.repId,
          proTime: this.evolveForm.time,
          proState: this.evolveForm.state,
          proRemark: this.evolveForm.describe
        }
      }).then(res => {
        this.$message.success("添加进展成功");
        this.getTaskData();
        this.addEvolveDialog = false;
      }).catch(err => {
        this.isError(err,this)
      });
    },
    //关闭进展
    closeAddEvolve() {
      this.evolveForm = {
        time: dateFtt("yyyy-MM-dd hh:mm:ss", new Date()),
        state: "",
        describe: "",
        imgUploadList: [],
        imgDelId: []
      };
    },
    //选中数据表的某一行
    selectTaskRow(row) {
      this.dataRow = row;
      // console.log(this.taskRow);
    },
    //打开维保归属对话框
    selectAddressBelong(str){//选择地址
      this.taskAffiliationDialogTitle = str
      this.taskAffiliationDialog = true;
    },
    selectRoomDblclick(row) {
      if (row) {
        this.addTaskFrom.affiliation = row.address;
        this.addTaskFrom.hsId = row.hsId;
        this.addTaskFrom.affiliationType = `${row.checkList}列表`;
      }
      this.taskAffiliationDialog = false;
    },
    //详细 修改负责人
    dbRow(row) {
      console.log(row);
      this.$axios({
        url: `/common1/oddjobs/${row.repId}`,
        method: "get"
      }).then(res => {
        this.taskRow = res.data.result;
        console.log(this.taskRow)
        console.log(res.data.result)
        let data = this.taskRow;
        let a =
          data.addCity == null || data.addCity == undefined
            ? ""
            : `${data.addCity}\u3000`;
        let b =
          data.addCommunity == null || data.addCommunity == undefined
            ? ""
            : `${data.addCommunity}\u3000`;
        let c =
          data.addRoomType == null || data.addRoomType == undefined
            ? ""
            : `${data.addRoomType}\u3000`;
        let d =
          data.addBuilding == null || data.addBuilding == undefined
            ? ""
            : `${data.addBuilding}\u3000`;
        let e =
          data.addDoorplateno == null || data.addDoorplateno == undefined
            ? ""
            : `${data.addDoorplateno}`;

        let f =
          data.repHouseId == null || data.repHouseId == undefined
            ? ""
            : `${data.repHouseId}\u3000`;
        let g =
          data.repHouse4rentId == null || data.repHouse4rentId == undefined
            ? ""
            : `${data.repHouse4rentId}\u3000`;
        let h =
          data.repHouse4storeId == null || data.repHouse4storeId == undefined
            ? ""
            : `${data.repHouse4storeId}\u3000`;
        let k =
          data.repCocId == null || data.repCocId == undefined
            ? ""
            : `${data.repCocId}`;
        if (a == "项目" || a == "库房" || a == "供应商" || a == "公区") {
          b = a;
        }
        if (a != "项目" && a != "库房" && a != "供应商" && a != "公区") {
          data.keyAdministrator = b + c + d + e;
        }
        if (
          (data.hsId == null || data.hsId == "") &&
          (data.houseCoding == null || data.houseCoding == "")
        ) {
          if (
            data.repCocId !== null &&
            data.repCocId !== "" &&
            data.repCocId != undefined
          ) {
            data.keyAdministrator = data.repContacts;
          } else {
            data.keyAdministrator = "无归属维保";
          }
        }
        if (data.repReturningRp == "未回访") {
          data.repReturningRp = "未复核";
        }
        if (data.repUseTime == "") {
          if (data.repToReceive == "") {
            data.repUseTime = "维保未领取";
          } else {
            let end = new Date();
            let statr = data.repToReceive;
            data.repUseTime = taskCostTime(statr, end);
          }
        } else {
          let end = data.repUseTime;
          let statr = data.repToReceive;
          data.repUseTime = taskCostTime(statr, end);
        }
        console.log(data);

        this.taskDetailDialog = true;
      }).catch(err => {
        this.isError(err,this)
      })
      this.getEvolveData(row);
      this.getReturnData(row);
     
    },
    modiPrincipal() {
      this.principalForm.oldPrincipal = [
        this.taskRow.storefrontId.toString(),
        this.taskRow.departmentId.toString(),
        this.taskRow.repUserId.toString()
      ];

      this.modiPrincipalDialog = true;
    },
    closePrincipal() {
      this.principalForm = {
        oldPrincipal: "",
        newPrincipal: "",
        newEvo: "",
        topic: ""
      };
    },
    saveModiPrincipal() {
      this.$axios({
        url: `/common1/oddjobs/${this.taskRow.repId}/updateRepairPeople`,
        method: "put",
        data: {
          oddjobsType: 1,
          oldRepPeople: gerUserName(
            this.principalForm.oldPrincipal[2],
            this.userName
          ),
          newRepPeople: gerUserName(
            this.principalForm.newPrincipal[2],
            this.userName
          ),
          proRemark: this.principalForm.newEvo,
          sendMessage: this.principalForm.topic == true ? 1 : 0,
          sendWxMessage: 0,
          repRepairPeopleId: this.principalForm.newPrincipal[2]
        }
      }).then(res => {
        this.$message.success("修改成功");
        this.getTaskData();
        this.modiPrincipalDialog = false;
        this.taskDetailDialog = false;
      }).catch(err => {
        this.isError(err,this)
      });
    },
    // },
    next(state) {
      let data = this.getIndex(this.taskData, this.dataRow, state, this);
      if(!data) {
        return
      }
      this.dataRow = data;
      this.$refs.taskData.setCurrentRow(data);
      this.dbRow(data);
    },
    //把每一行的索引放进row
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    //点击领取单元格
    clickToReceive(index, row) {
      console.log(index, row);
      if (row.repToReceive == "未领取") {
        this.$confirm("确定要领取维保吗？")
          .then(_ => {
            console.log("确定领取");
            this.$axios({
              url: `/common1/oddjobs/${row.repId}/receive`,
              method: "put"
            }).then(res => {
              console.log(res);
              this.getTaskData();
            }).catch(err => {
              this.isError(err,this)
            })
          })
          .catch(_ => {
            console.log("取消领取");
          });
      }
    },
    //点击复核单元格
    clickReturning(index, row) {
      console.log(index, row);
      if (row.repState != "未领取") {
        if (typeof this.authe.Hotel_Administration_RepairClean_AddReview == 'undefined') {
          this.$message.warning('无权限操作')
          return
        }
        this.returnFrom.name = row.repairMan;
        this.getCurrentUser();
        this.returnDialog = true;
      } else {
        this.$message.warning("维保未完成，无法完成复核！");
      }
    },
    //保存复核
    saveReturn() {
      this.$axios({
        url: `/common1/oddjobsInterview/check`,
        method: "post",
        data: {
          retRepairId: this.dataRow.repId,
          retResult: this.returnFrom.result,
          rteRemark: this.returnFrom.cause
        }
      }).then(res => {
        this.$message.success("保存成功");
        this.getTaskData();
        this.returnDialog = false;
      }).catch(err => {
        this.isError(err,this)
      });
    },
    //关闭复核
    closeReturnDialog() {
      this.returnFrom = {
        name: "",
        result: "合格",
        cause: ""
      };
    },

    //打开附件
    accessory() {
      this.evolveForm.random = parseInt((Math.random() * 9 + 1) * 100000000000);
      this.getPhoto();
      this.accessoryDia = true;
    },
    closeAccessory() {
      this.evolveForm.imgUploadList = [];
      this.evolveForm.imgDelId = [];
      this.recoverImg();
    },
    saveAccessory() {
      console.log("保存附件");
      this.$axios({
        url: `/common1/oddjobs/upload/${this.taskRow.repId}`,
        method: "put",
        data: {
          randomNum: this.evolveForm.random,
          deleteImgList: this.evolveForm.imgDelId,
          tableType: "维保"
        }
      }).then(res => {
        this.$message.success("处理成功");
        this.accessoryDia = false;
      }).catch(err => {
        this.isError(err,this)
      });
    },
    //选择数据条数
    sizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getTaskData();
    },
    //具体那一页
    currentChange(current) {
      this.currentPage = current;
      this.dataRow = "";
      this.getTaskData();
    },
    //获取当前用户
    getCurrentUser() {
      this.$axios({
        url: "/common1/users/currentUser",
        method: "get"
      }).then(res => {
        console.log(res);
        this.returnFrom.name = res.data.result.suStaffName;
      }).catch(err => {
        this.isError(err,this)
      })
    },

    //手机上传
    phoneUpload() {
      if (this.addTaskDialog) {
        this.getPhoneUploadUrl(this.addTaskFrom.random, "私有", "图片", null);
      } else {
        this.getPhoneUploadUrl(
          this.evolveForm.random,
          "私有",
          "图片",
          this.taskRow.repId
        );
      }
      this.phoneUploadDia = true;
    },
    //关闭手机上传
    closePhoneUploadDia() {
      if (this.addTaskDialog) {
        this.getTempImg(this.addTaskFrom.random);
      } else {
        this.getTempImg(this.evolveForm.random);
      }
    },
    //恢复图片
    recoverImg() {
      this.$axios({
        url: `/common1/photos/recovery/${this.taskRow.repId}`,
        method: "PUT",
        params: {
          photoTableType: "维保"
        }
      }).then(res => {

      }).catch(err => {
        this.isError(err,this)
      })
    },
    //获取手机上传url
    getPhoneUploadUrl(random, bucket, fileType, tableId) {
      this.$axios({
        url: `/common1/photos/getMobUploadUrl`,
        method: "get",
        params: {
          photoRandom: random,
          photoBucket: bucket,
          photoMimeType: fileType,
          photoTableType: "维保",
          photoTableId: tableId,
          photoUrl: null,
          photoUserId: null
        }
      }).then(res => {
        console.log(res.data.result);
        this.QRCodeMsg = res.data.result;
      }).catch(err => {
        this.isError(err,this)
      })
    },
    //查询临时图片
    async getTempImg(random) {
      try {
        let imgArr = [];
        let imgdata = [];
        await this.$axios({
          url: `/common1/photos`,
          method: "get",
          params: {
            photoRandom: random,
            photoTableType: "维保",
            photoStatus: "临时"
          }
        }).then(res => {
          imgdata = res.data.result;
        }).catch(err => {
          this.isError(err,this)
        })
        for (let i = 0; i < imgdata.length; i++) {
          let obj = {
            name: imgdata[i].photoName,
            url: await this.getDownloadToken(imgdata[i].photoUrl),
            response: {
              result: imgdata[i].photoId
            }
          };
          imgArr.push(obj);
        }
        if (this.addTaskDialog) {
          if (this.addTaskFrom.imgUploadList.length == 0) {
            this.addTaskFrom.imgUploadList = imgArr;
          } else {
            for (const item1 of imgArr) {
              let flag = true;
              for (const item2 of this.addTaskFrom.imgUploadList) {
                if (item1.response.result == item2.response.result) {
                  flag = false;
                  break;
                }
              }
              if (flag) {
                this.addTaskFrom.imgUploadList.push(item1);
              }
            }
          }
        } else {
          if (this.evolveForm.imgUploadList.length == 0) {
            this.evolveForm.imgUploadList = imgArr;
          } else {
            for (const item1 of imgArr) {
              let flag = true;
              for (const item2 of this.evolveForm.imgUploadList) {
                if (item1.response.result == item2.response.result) {
                  flag = false;
                  break;
                }
              }
              if (flag) {
                this.evolveForm.imgUploadList.push(item1);
              }
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    },

    //查询已保存的图片
    async getPhoto() {
      try {
        let imgArr = [];
        let imgdata = [];
        await this.$axios({
          url: `/common1/photos`,
          method: "get",
          params: {
            photoTableId: this.taskRow.repId,
            photoTableType: "维保",
            photoStatus: "正常"
          }
        }).then(res => {
          imgdata = res.data.result;
        }).catch(err => {
          this.isError(err,this)
        })
        for (let i = 0; i < imgdata.length; i++) {
          let obj = {
            name: imgdata[i].photoName,
            url: await this.getDownloadToken(imgdata[i].photoUrl),
            response: {
              result: imgdata[i].photoId
            }
          };
          imgArr.push(obj);
        }
        this.evolveForm.imgUploadList = imgArr;
      } catch (err) {
        console.log(err);
      }
    },
    //获取下载凭证
    getDownloadToken(str) {
      return this.$axios({
        url: `/common1/photos/getDownloadUrl`,
        method: "get",
        params: {
          baseUrls: str
        }
      }).then(res => {
        return res.data.result[0].original;
      }).catch(err => {
        this.isError(err,this)
      })
    },
    //获取token
    getToken() {
      return this.$axios({
        url: "/common1/photos/token/callback",
        method: "get",
        params: {
          bucket: "私有"
        }
      }).then(res => {
        return res.data.result;
      }).catch(err => {
        this.isError(err,this)
      })
    },

    //上传前
    async beforeUpload(file) {
      try {
        console.log(file);
        let getToken = await this.getToken();
        this.picData.token = getToken;
        file.type == "image/jpeg"
          ? (this.picData["x:photoMimeType"] = "图片")
          : (this.picData["x:photoMimeType"] = "文件");
        this.picData["x:filename"] = file.name;
        this.picData["x:co"] = sessionStorage.getItem("co");
        this.picData["x:photoTableType"] = "维保";
        this.picData["x:photoBucket"] = "私有";

        if (this.addTaskDialog) {
          this.picData["x:photoRandom"] = this.addTaskFrom.random;
        } else {
          this.picData["x:photoTableId"] = this.taskRow.repId;
          this.picData["x:photoRandom"] = this.evolveForm.random;
        }
      } catch (err) {
        console.log(err);
      }
    },
    //上传成功后
    handleAvatarSuccess(res, file, fileList) {
      if (this.addTaskDialog) {
        this.addTaskFrom.imgUploadList = fileList;
      } else {
        this.evolveForm.imgUploadList = fileList;
      }
    },
    //删除前
    beforeRemove(file) {
      console.log(file);
      if (this.addTaskDialog) {
        return this.$confirm(`确定移除该文件？`).then(() => {
          this.$axios({
            url: `/common1/photos`,
            method: "DELETE",
            params: {
              bucket: "私有",
              photoIds: file.response.result
            }
          }).then(res => {

          }).catch(err => {
            this.isError(err,this)
          });
        });
      } else {
        return this.$confirm(`确定移除该文件？`).then(() => {
          this.evolveForm.imgDelId.push(file.response.result);
          this.$axios({
            url: `/common1/photos/failure/${file.response.result}`,
            method: "put"
          }).then(res => {

          }).catch(err => {
            this.isError(err,this)
          });
        });
      }
    },
    //删除后
    handleRemove(file, fileList) {  
      if (this.addTaskDialog) {
        this.addTaskFrom.imgUploadList = fileList;
      } else {
        this.evolveForm.imgUploadList = fileList;
      }
    },
    //查看某张图片的原图
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
      console.log(file);
    },
    //上传失败
    handleError(res) {
      //显示错误
      console.log(res);
    }
  },
};
</script>

<style lang="scss" scoped>
.red {
  color: red;
}

.blue {
  color: blue;
}
.describe /deep/.el-col {
  font-size: 14px;
}
</style>
